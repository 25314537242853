<template>
	<transition name="modal">
		<div
			v-if="active"
			:key="item.slug"
			class="modal"
			:style="translate"
			@click="onClick"
			@touchstart="onTouchstart"
			@touchmove="onSwipe"
			@touchend="onSwipe"
		>
			<div
				ref="modal"
				class="modal-body"
			>
				<div class="modal-layer modal-start">
					<div class="modal-inner">
						<!-- Title -->
						<h2 class="modal-title">
							{{ item.name }}
							<span
								v-if="item.alias"
								class="modal-alias"
							>
								{{ item.alias }}
							</span>
						</h2>

						<!-- Icon -->
						<figure class="modal-icon">
							<BaseIcon :src="'animals/' + (item.icon || item.slug + '.svg')" />
						</figure>

						<BaseButtonClose @click.native="close" />
					</div>
				</div>
				<div class="modal-layer modal-end">
					<div class="modal-inner modal-content">
						<!-- Meta -->
						<MetaList :items="item" />

						<!-- Related -->
						<h3 class="modal-subtitle">
							Andere Tiere aus dieser Zeit
						</h3>
						<AppCards
							:items="relatedAnimals"
							layout="slider"
						/>

						<div v-if="content">
							<h3 class="modal-subtitle">
								Wikipedia
								<BaseExternalLink
									:link="item.link"
									:title="'Mehr über ' + item.name + ' auf Wikipedia'"
								/>
							</h3>
							<div
								class="content"
								v-html="content"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import {EventBus} from '../event-bus';
import {fields} from '../data/filter';
import {next, prev} from '@schascha/brabbelback';


export default {
	name: 'AppModal',
	components: {
		AppCards: () => import(/* webpackChunkName: "AppCards" */ '@/components/AppCards'),
		MetaList: () => import(/* webpackChunkName: "MetaList" */ '@/components/meta/MetaList'),
	},
	data() {
		return {
			active: false,
			item: null,
			rel: null,
			touchstartX: 0,
			//touchstartY: 0,
			touchendX: 0,
			//touchendY: 0,
			touchDistance: 100,
			translate: ''
		};
	},
	computed: {
		items() {
			return this.rel || this.$store.getters.getAnimalsByErathem();
		},
		relatedAnimals() {
			return this.$store.getters.relatedAnimals(this.item);
		},
		content() {
			if (!this.item || !this.item.link) {
				return;
			} else {
				return this.$store.state.wikipedia.content;
			}
		},
		fields() {
			return fields;
		},
		prev() {
			const {item, items} = this;

			return prev(items.findIndex((e) => e.name === item.name), items);
		},
		next() {
			const {item, items} = this;

			return next(items.findIndex((e) => e.name === item.name), items);
		}
	},
	watch: {
		item() {
			if (!this.item || !this.item.link) {
				return;
			}

			this.$store.dispatch('getWikipediaPage', {
				page: this.item.name
			});
		},
		items() {
			this.onLocationStart();
		}
	},
	created() {
		EventBus.$on('Modal:open', this.open);
		EventBus.$on('Modal:close', this.close);
		document.addEventListener('keydown', this.onKeypress);
		window.addEventListener('popstate', this.onPopstate);
		this.onLocationStart();
	},
	beforeDestroy() {
		EventBus.$off('Modal:open');
		EventBus.$off('Modal:close');
		document.removeEventListener('keydown', this.onKeypress);
		window.removeEventListener('popstate', this.onPopstate);
	},
	methods: {
		open(e, history = true) {
			const {name, slug, rel} = e;

			if (!e || !name || e.repeat) {
				return;
			}

			if (rel) {
				this.rel = rel;
			}

			this.item = e;
			this.active = true;
			document.body.classList.add('show-modal');

			if (history) {
				window.history.pushState(e, null, slug);
			}

			if (this.$refs.modal) {
				this.$refs.modal.scrollTop = 0;
			}
		},
		close() {
			this.rel = null;
			this.item = null;
			this.active = false;
			document.body.classList.remove('show-modal');
			window.history.pushState({}, null, '/');
		},
		onKeypress(e) {
			// Prevent repeating on key hold
			if (!this.active || e.repeat) {
				return;
			}

			// Esc
			if (e.keyCode == 27) {
				this.close();
			}

			// Left arrow
			if (e.keyCode === 37) {
				this.open(this.prev);
			}

			// Right arrow
			if (e.keyCode === 39) {
				this.open(this.next);
			}
		},
		onSwipe(e) {
			// Only swipe at the beginning of the modal
			if (this.$refs.modal.scrollTop > 400) {
				return;
			}

			this.touchendX = e.changedTouches[0].screenX;
			this.touchendY = e.changedTouches[0].screenY;

			const
				isScrolled = (Math.abs(this.touchstartY - this.touchendY) > this.touchDistance),
				isSwipedLeft = (this.touchendX + this.touchDistance < this.touchstartX),
				isSwipedRight = (this.touchendX > this.touchstartX + this.touchDistance)
			;

			switch (e.type) {
				case 'touchmove':
					if (!isScrolled && (isSwipedLeft || isSwipedRight)) {
						var
							delta = Math.abs(this.touchstartX - this.touchendX),
							translate = Math.min(delta, this.touchDistance)
						;

						if (this.touchendX < this.touchstartX) {
							translate = translate * -1;
						}

						this.translate = 'transform: translateX(' + translate + 'px) scale(0.9);';
					} else {
						this.translate = '';
					}

					break;
				case 'touchend':
					this.translate = '';

					if (isScrolled) {
						return;
					}

					if (isSwipedLeft) {
						// Swiped left
						this.open(this.next);
					} else if (isSwipedRight) {
						// Swiped right
						this.open(this.prev);
					}
					break;
			}
		},
		onTouchstart(e) {
			this.touchstartX = e.changedTouches[0].screenX;
			this.touchstartY = e.changedTouches[0].screenY;
		},
		onPopstate(e) {
			if (e && e.state && e.state.length) {
				this.open(e.state, false);
			} else if (this.active) {
				this.close();
			}
		},
		onLocationStart() {
			if (!this.active && this.items.length) {
				const path = document.location.pathname.match(/^\/([a-z]+)$/);

				if (path && path[1]) {
					this.open(this.$store.state.animals.find((el) => el.slug === path[1]));
				}
			}
		},
		onClick(e) {
			// Scroll to anchor
			const
				el = e.target.closest('a')
			;

			if (e.target.matches('.modal-layer')) {
				this.close();
			}

			if (el && el.href && el.hash && el.pathname === window.location.pathname) {
				var
					id = decodeURIComponent(el.hash.replace('#', '')),
					anchor = document.getElementById(id)
				;

				if (anchor) {
					e.preventDefault();

					anchor.scrollIntoView({
						behavior: 'smooth'
					});
				}
			}
		}
	}
};
</script>

<style lang="scss">
	@import '../scss/components/modal';

	.modal {
		transition: all 0.3s ease;
		overflow: auto;
		transform-origin: bottom;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 11;
		width: 100%;
		height: 100%;

		&-body {
			overflow-x: hidden;
			overflow-y: auto;
			perspective: 1px;
			position: relative;
			z-index: 1;
			height: 100vh;
		}

		&-layer {
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
		}

		&-start {
			transform: translateZ(-1px) scale(2);
		}

		&-end {
			transform: translateZ(0);
			top: 400px;
		}

		&-inner {
			position: relative;
			padding: 1.25rem 0;
			min-height: 100vh;

			@include mq($break) {
				margin: 0 0 0 3rem;
			}
		}

		&-content {
			--cards-space-x: -2rem;
			padding: 2rem;
			min-height: calc(100vh - 400px);
			background-color: var(--color-bg);
			border-radius: 2rem 2rem 0 0;

			@include mq($break) {
				--cards-space-x: -8rem;
				padding: 4rem 8rem;
				border-radius: 2rem 0 0;
			}
		}

		&-title {
			position: relative;
			z-index: 1;
			padding: 0 4rem 0 2rem;
			color: var(--color-bg);

			@include mq($break) {
				padding-left: 0;
			}
		}

		&-subtitle {
			margin: 3rem 0 2rem;
		}

		&-alias {
			display: block;
			font-size: 0.75em;
			font-weight: 300;
		}

		&-icon {
			position: absolute;
			top: 1.5rem;
			right: 1.5rem;
			left: 1.5rem;
			margin: 0;

			svg {
				margin: 0 auto;
				width: 100%;
				max-width: 100%;
				height: 400px;
				max-height: 100%;
				fill: var(--color-brand);
			}
		}

		.close {
			--color-button-close: #{$color-text};
			position: absolute;
			top: 1.5rem;
			right: 1.5rem;
			z-index: 2;
			color: $color-white;
		}
	}

	.modal-enter-active,
	.modal-leave-active {
		transition: all 0.3s ease-out;
	}

	.modal-enter,
	.modal-leave-active {
		opacity: 0;
		transform: scale(1.1);
	}

	.show-modal {
		overflow: hidden;

		.overlay {
			opacity: 1;
		}
	}
</style>
